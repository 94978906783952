@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400;600;700&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h2 {
  font-size: 26px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 19px;
  font-weight: normal;
}
h6 {
  font-size: 18px;
  text-transform: uppercase;
}
p {
  font-size: 14px;
}

.p-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
body { 
  background: rgb(12,12,12);

  overflow-x: hidden;
  color: white;
  font-family: 'Gabarito', cursive;
}

:root {
  --linkColor: #c72329;
  --bgColor: #0c0c0c;
  --selectedColor: rgba(30, 30, 30, 1);
  --secBgColor: #292a2b;
  --thiBgColor: #333333;
  --fourthBgColor: #686868;
  --textColor: #adb1b5;
  --secTextColor: white;
}

a {
  color: var(--linkColor);
  text-decoration: none;
}
p {
  color: var(--textColor);
}

@media only screen and (min-width: 500px) {
  .hideDesktop {
    display: none !important;
  }
}

@media only screen and (max-width: 500px) {
  .hide {
    display: none !important;
  }
}

.hidePhoneMenu {
  display: none !important;
}
