
.not-playing {
  margin-top: 10px;
  width: 100px;
  height: 80px;
  border-radius: .3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: .5rem;
  box-sizing: border-box;
  gap: 1px;
}
.not-playing-icon-pause:hover + .playing {
  opacity: 0.6;
}
.not_playing__bar {
  display: inline-block;
  background: white;
  width: 30%;
  height: 0%;
}
.playing {

    margin-top: 10px;

    width: 100px;
    height: 80px;
    border-radius: .3rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: .5rem;
    box-sizing: border-box;
    gap: 1px;
  }
  
  .playing__bar {
    display: inline-block;
    background: white;
    width: 30%;
    height: 100%;
    animation: up-and-down 1.3s ease infinite alternate;
  }
  
  .playing__bar1 {
    height: 60%;
  }
  
  .playing__bar2 {
    height: 30%;
    animation-delay: -2.2s;
  }
  
  .playing__bar3 {
    height: 75%;
    animation-delay: -3.7s;
  }
  .playing__bar4 {
    height: 45%;
    animation-delay: -3s;
  }
  .playing__bar5 {
    height: 50%;
    animation-delay: -2.4s;
  }
  .playing__bar6 {
    height: 80%;
    animation-delay: -3s;
  }
  .playing__bar7 {
    height: 40%;
    animation-delay: -2.9s;
  }
  
  @keyframes up-and-down {
    10% {
      height: 30%;
    }
    
    30% {
      height: 100%;
    }
    
    60% {
      height: 50%;
    }
    
    80% {
      height: 75%;
    }
    
    100% {
      height: 60%;
    }
  }