.player-bottom-component-container {

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65px;
    background-color: var(--secBgColor);
    /*border-top: 1px solid var(--thiBgColor);*/

    display: flex;
    justify-content: space-between;
    padding: 0 25px 0 25px;
    z-index: 300;
}

.play-and-pause {
    display: flex;
    align-items: center;
}
.title-container {
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    flex: 2;
}

.song-title-and-album {
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}
.album-title {
    color: var(--textColor);
}

.other-controls {
    min-width: 300px;
    display: flex;
    justify-content: right;
    align-items: center;
    flex: 1;
}

.time-container-bottom-player {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    color: var(--textColor);
}
.time-container-bottom-player span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.time-line-container-bottom {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 15px;

    border-radius: 4px;

    align-self: flex-end;
    cursor: pointer;

    z-index: 200;
}
.time-line-bottom-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    background-color: var(--thiBgColor);

    width: 100%;
    height: 2px;

}
.time-line-bottom-buffer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    border-radius: 10px;
    z-index: 95;
    background-color: rgb(114, 114, 114);

}
.time-line-bottom {
    position: relative;
    width: 100%;
    height: 1px;
    border-radius: 10px;
    background-color: #ec494e;
    z-index: 100;
    
}
.time-line-bottom:hover {
    position: relative;
    cursor: pointer;
    background-color: #be0302;

}
.time-line-container-bottom:hover .time-line-bottom {
    position: relative;
    cursor: pointer;
    height: 3px;
    top: -2px;
    background-color: #be0302;
}



.player-bottom-content-position {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.player-bottom-left {
    display: flex;
    gap: 20px;
}


.bottom-player-volume-control-container {

    display: none;
    justify-content: center;
    align-items: center;
}

.bottom-player-volume-control-body {
   /* background-color: var(--thiBgColor);*/
    /*border-left: 1px solid var(--textColor);
    border-right: 1px solid var(--textColor);*/
    width: 120px;
    height: 50px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
}

.bottom-player-volume-control-box {
    width: 100px;
    height: 5px;
    border-radius: 10px;
    /*background-color: red;*/
    cursor: pointer;
    display: flex;
    position: absolute;
}
#bottom-player-volume-control {
    background-color: var(--bgColor);
}

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    border-radius: 10px;

    cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: white;
    height: 15px;
    width: 15px;
    border-radius: 50%;
}
.open {
    display: flex;
}

.ForRef {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

}

.back-to-player {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: -130px;
    right: 30px;

    width: 100px;
    height: 100px;

    border-radius: 8px;

    cursor: pointer;
}
.back-to-player-icon {
    font-size: 30px;
}
.back-to-player-img {
    position: absolute;

    width: 100px;
    height: 100px;

    background-position: center;
    border-radius: 8px;

    z-index: -1;
}
.close {
    display: none;
}

@media only screen and (max-width: 920px) and (min-width: 500px) {
    .medium-control {
        display: flex !important;
    }
}


@media only screen and (max-width: 920px) {
    .back-to-player {
        display: none;
    }
    .other-controls {
        display: none;
    }
    .time-container-bottom-player {
        display: none;
    }
    .medium-control {
        display: none;
    }
    .settings {
        display: none;
    }
    .title-container {
        justify-content: flex-start;
        min-width: 0;
    }
    .player-bottom-content-position {
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
    }
}