.player-container {
    width: 100%;
    height: calc(100dvh - 250px);
    padding: 0;

    position: fixed;
    top: 120px;
    left: 0;

    background-color: var(--bgColor);

    display: none;
    justify-content: space-evenly;
    align-items: flex-start;

    z-index: 200;

}

.player-background {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: auto;

    background: rgb(12,12,12);
    background: radial-gradient(circle, rgba(12,12,12,1) 0%, rgba(23,23,23,1) 0%, rgba(12,12,12,1) 100%);
}

.player {
    width: 400px;
    height: 400px;

    /*border: 1px solid pink;*/
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 101;

    margin: 0 50px;

    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.player:hover {
    cursor: pointer;
}
.player:hover .player-playbtn-container {
    visibility: visible;
    opacity: 1;
}


.player-avatar {
    width: auto;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

}

/* Shine hover */
.player-hover div {
    position: relative;
    width: 600px;
    height: 600px;
}
.player-hover figure {
	position: relative;
}
.player-hover figure::before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.player-hover figure:hover::before {
	-webkit-animation: shine .75s;
	animation: shine .75s;
}
@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}


.time-container-bottom-player-mobile {
    display: none;
}


.player-title-container {
    display: flex;
    align-items: center;
    flex-flow: column-reverse;
    color: white;
    z-index: 10;

    width: 400px;
    height: 400px;
}
.player-title {
    margin-bottom: 100px;

}
.player-title h1 {
    position: absolute;
    left: 20px;
    bottom: 60px;
    background-color: black;
    padding: 8px;
    font-size: 24px;
}
.player-title h3 {
    position: absolute;
    left: 20px;
    bottom: 20px;
    background-color: black;
    padding: 8px;
    font-weight: normal;
    font-size: 16px;
}


.player-borito-background {
    width: 400px;
    height: 400px;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.player-playbtn-container {
    position: absolute;
    bottom: 30px;
    right: 40px;

    z-index: 10;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s linear;
}

.audio-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: relative;
}
.play-audio {
    min-width: 40px;
    font-size: 35px;
    cursor: pointer;
    margin: 0 5px;
    align-self: center;
}
.player-playbtn {
    min-width: 40px;
    font-size: 50px;
    cursor: pointer;
    align-self: center;
    opacity: 0.7;
}
.small-control {
    font-size: 16px;
    color: var(--textColor);
    transition: 0.8s ease;
}
.small-control:hover {
    color: white;
}
.medium-control {
    font-size: 26px;
}
.audio-detail {
    text-align: center;
    text-shadow: 2px 0 2px rgba(0, 0, 0, 0.62);

}
.audio-detail-album-name {
    color: var(--textColor);
}
.audio-detail-time {
    min-width: 465px;
    color: var(--textColor);
    display: flex;
    justify-content: space-between;
    font-size: 19px;
    font-weight: bold;
    /*background-color: gold;*/
    margin-top: 10px;
}
.time-line-container {
    width: 340px;
    height: 10px;
    border-radius: 8px;
    background-color: var(--thiBgColor);
    align-self: flex-end;
    cursor: pointer;
}
.time-line {
    width: 100%;
    height: 10px;
    border-radius: 10px;
    background-color: white;
}
.time-line:hover {
    position: relative;
    cursor: pointer;
    height: 15px;
    top: -5px;
}
.time-line-container:hover .time-line {
    position: relative;
    cursor: pointer;
    height: 15px;
    top: -5px;
}

.casette-div {
    width: 358px;
    height: 233px;
    margin-top: 60px;
    display: flex;
    justify-content: center;
}
.casette-tittle {
    color: black;
    font-weight: bold;
    margin-top: 30px;

}

.audio-detail-title {
    margin-top: 10px;
    font-size: 45px;
    font-weight: bold;
}

.time-current {
    min-width: 55px;
}
.time-over {
    min-width: 55px;
}





.album-name-and-releases {
    min-width: 400px;
    /*background-color: green;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.album-container {
    width: 385px;
    height: 440px;
    padding: 18px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 60px;
     background: rgba( 255, 255, 255, 0.05 );
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.album-container:hover {
    cursor: pointer;
    background: rgba(107, 107, 107, 0.25);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.album-image {
    width: 350px;
    border-radius: 15px;
    margin-top: 9px;
}
.album-description {
    padding: 15px;
}
.album-description h3 {
    font-size: 20px;
}
.album-description p {
    color: var(--textColor);
    font-size: 14px;
}


.up-next-container {
    min-width: 460px;
    /*background-color: gold;*/
    padding: 0 20px;
    position: relative;
    overflow-x: auto;
}
.container-title {
    color: var(--fourthBgColor);
    cursor: pointer;
}
.container-line-up-next, .container-line-lyrics {
    position: relative;
    top: 8px;
    left: 0;
    min-width: 100px;
    height: 1px;

}
.active-tab {
    color: white;
}
.active-tab-line {
    background-color: white;
}

.up-next-songs {
    width: 100%;
    height: calc(100dvh - 300px);

    margin-top: 20px;
    overflow: auto;
}

.up-next-song {

    min-height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 0 10px 0 0;
    cursor: pointer;
    border-bottom: 1px solid var(--thiBgColor);

    border-radius: 5px;
}
.up-next-song:last-child {
    border-bottom: none;
}
.up-next-song:hover {
    background-color: var(--secBgColor);
}

.selected {
    background-color: var(--selectedColor);
}

.up-next-song-detail {
    display: flex;
    flex-direction: column;
    flex: 26;
}
.no-movable-div-song-card {
    display: flex;
    flex-direction: row;
    flex: 30;
}
.on-not-playing-staggered {
    cursor: move;
}
.up-next-song-title {
    font-weight: bold;
    font-size: 18px;
}
.up-next-song-album {
    font-size: 14px;
    color: var(--textColor);
}
.up-next-song-min-con {
    display: flex;
    align-items: center;
}
.up-next-song-min {
    font-size: 17px;
    color: var(--textColor);
}

.song-card-drag {
    user-select: none;
}

.new-releases {
    min-width: 385px;
    height: 300px;
    margin-top: 50px;
    padding: 20px;
    border-radius: 10px;
    background: rgba( 255, 255, 255, 0.05 );
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.new-release-songs {
    width: 342px;
    height: 230px;
    overflow: auto;
    margin-top: 10px;
    /*background-color: red;*/

}
.new-release-song {
    display: flex;
    /*background-color: green;*/
    border-radius: 10px;
    padding: 10px;
    align-items: center;
}
.new-release-song:hover {
    cursor: pointer;
    background-color: var(--thiBgColor);
}
.release-song-image {
    background-size: cover;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin-right: 20px;
}
.release-song-detail {
    display: flex;
    flex-direction: column;
}

.play-audio {
    padding: 10px 0;
    z-index: 10;
}
.active-btn {
    background-color: var(--thiBgColor);
    border-radius: 8px;
}

/* --- VOLUME CONTROL --- */
.volume-control-container {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 105px;
    position: absolute;
    top: -52px;
    left: 60px;
    z-index: 1;
}
.volume-control-body {
    background-color: var(--thiBgColor);
    /*border-left: 1px solid var(--textColor);
    border-right: 1px solid var(--textColor);*/
    width: 45px;
    height: 105px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    position: relative;
}
.volume-control-box {
    margin-top: 36px;
    width: 52px;
    height: 5px;
    border-radius: 10px;
    /*background-color: red;*/
    cursor: pointer;
    display: flex;
    position: absolute;
    transform: rotate(-90deg);
}/*
.volume-control {
    margin-top: 10px;
    -webkit-appearance: none;
    width: 20px;
    height: 50px;
    border-radius: 10px;

    outline: none;
}
*/
input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secBgColor);
    cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: white;
    height: 15px;
    width: 15px;
    border-radius: 50%;
}

.open {
    display: flex;
}

.close {
    display: none;
}


.playlist-and-lyrics {

    flex: 6;
    width: 800px;
    height: calc(100dvh - 250px);
}

.up-next-tabs {
    display: flex;
    gap: 100px;
    margin-bottom: 30px;
}

.up-next-cover-url {
    width: 30px;
    height: 30px;

}

.up-next-url-container {
    cursor: move;
    flex: 2;
}


.up-next-song-min-con {
    flex: 1;
}
.mobile-player-title {
    display: none;
}
.mobile-player-header {
    display: none;
}
.mobile-player-btns {
    display: none;
}
.time-line-container-mobile {
    display: none;
}
@media only screen and (max-width: 920px) and (min-width: 500px) {
    .player-container {
        align-items: center;
        flex-direction: column;
        gap: 40px;
    }
    .up-next-container {
        padding: 0;
    }
    .up-next-songs {
        width: 100%;
    }
    .playlist-and-lyrics {
        width: 90vw;

    }
    .lyrics-modal {
        height: calc(100dvh - 300px);
    }

}

@media only screen and (max-width: 500px) {
    .player-container {
        margin-top: 0;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;

        position: fixed;
        top: 20px;
        left: 0;
    }
    .playlist-and-lyrics {
        display: none;
    }
    .player-borito-background {
        width: 100%;
        height: 100%;
    }
    .player {
        width: 200px;
        height: 200px;
    }
    .mobile-player-title {
        display: block;
        z-index: 900;
        text-align: center;
    }
    .player-playbtn-container {
        display: none;
    }
    .player-title-container {
        display: none;
    }

    .mobile-player-header {
        display: flex;
        align-items: center;
        padding: 0 15px;
        z-index: 900;

        width: 100%;

        height: 65px;

    }
    .down-icon {
        font-size: 28px;

        padding: 15px;
        cursor: pointer;
    }

    .mobile-player-btns {
        z-index: 900;
        display: flex;
        gap: 30px;
    }
    .player-playbtn-mobile {
        font-size: 75px;
        cursor: pointer;
    }
    .mobile-player-title-h3 {
        font-size: 24px;
    }
    .mobile-player-title-p {
        margin-top: 10px;
        font-size: 17px;
    }

    .time-line-container-mobile {
        
        display: block;
        width: 80%;

        border-radius: 4px;

        align-self: center;
        cursor: pointer;

        z-index: 200;

        position: relative;
        top: 0;
    }

    .time-line-mobile {
        
        width: 100%;
        height: 4px;
        border-radius: 10px;
        background-color: rgb(212, 212, 212);
        z-index: 900;

        position: relative;
        top: 3px;
    
    }
    .time-line-bg-mobile {
        z-index: 800;
        background-color: var(--thiBgColor);

        width: 100%;
        height: 4px;

        position: relative;
        top: 0;
    }
    .time-container-bottom-player-mobile {
        color: var(--textColor);
        font-size: 15px;
        z-index: 1000;

        width: 80%;

        display: flex;
        justify-content: space-between;
    }


}