.albums-container {
    width: 1000px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    gap: 40px;
    

    padding: 20px;
}
.album-card-container {
    width: 800px;
}

.album-card {
    width: 170px;
    height: 170px;
    position: relative;

    cursor: pointer;
    margin-bottom: 10px;
}

.album-card h4 {
    font-size: 18px;
}
.album-card h4:hover {
    text-decoration: underline;
}
.album-card p {
    font-size: 13px;
}

.album-title-h{
    color: white;
}

.album-card-icon-border {
    position: absolute;
    bottom: 22px;
    right: 22px;


    width: 40px;
    height: 40px;
    border-radius: 25px;
    background-color: black;
    opacity: 0.8;

    display: flex;

    justify-content: center;
    align-items: center;
    transition: width 0.3s, height 0.3s, bottom 0.3s, right 0.3s;
}
.album-card-icon-border:hover {
    width: 46px;
    height: 46px;
    bottom: 20px;
    right: 20px;
    opacity: 1;
}
.album-card-icon-border:hover .album-card-icon {
    font-size: 22px;
    padding-left: 4px;

}
.album-card-icon {

    font-size: 18px;

    padding-left: 3px;

    color: white;

    z-index: 100;
    transition: font-size 0.3s, padding-left 0.3s;
}



.album-card-img-container {
    width: 170px;
    height: 170px;

    overflow: hidden;

    border: 1px solid #151515;
    border-radius: 5px;
}

.album-card-img {
    width: 170px;
    height: 170px;

    background-size: cover;
    border-radius: 10px;
}

.album-card-link {
    margin-bottom: 8px;
}

@media screen and (max-width: 1120px) {
    .albums-container {
        width: 100vw;
    }
    .album-card-container {
        width: 100%;
    }
}