::-webkit-scrollbar {
    width: 14px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    background: rgba(135, 135, 135, 0.3);
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(143, 143, 143, 0.5);
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}