@media screen and (min-width: 1120px) {
    .member:hover {
        cursor: pointer;
        transform: scale(1.08) rotate(2deg);
        border-radius: 15px;
        -webkit-box-shadow: 2px 2px 34px -8px rgba(255,255,255,0.4);
        -moz-box-shadow: 2px 2px 34px -8px rgba(255,255,255,0.4);
        box-shadow: 2px 2px 34px -8px rgba(255,255,255,0.4);
        z-index: 200;
    }

 }

.members-container {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    margin-bottom: 60px;
}
.members {
    display: flex;
}

.member {
    position: relative;
    width: 220px;
    height: 450px;

    background-size: cover;
    background-position: center;

    -webkit-transition: all 150ms ease-in-out; 
    -moz-transition: all 150ms ease-in-out; 
    transition: all 150ms ease-in-out; 
}

.no-one {
    opacity: 0.5;
}
.no-one:hover {
    opacity: 1;
}

.member-title {
    position: absolute;

    bottom: 74px;
    left: 20px;

    z-index: 10;

    font-weight: bold;
    font-size: 24px;

    background-color: black;
    padding: 6px 10px;
    font-size: 20px;

}
.member-role {
    position: absolute;

    bottom: 40px;
    left: 20px;

    z-index: 10;

    background-color: black;
    padding: 6px 10px;

    font-size: 14px;
}


@media screen and (max-width: 1300px) {
    .members {
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }

}

@media screen and (max-width: 500px) {
    .member {
        width: 85vw;
    }

}
