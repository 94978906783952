.navbar-container {
    width: 100vw;

    position: relative;
    display: flex;
    padding: 0 0 0 30px;


    align-items: center;
    justify-content: space-between;
    z-index: 300;


}
.menu-content {
    min-width: 100px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 8px;
    border-radius: 14px;
    margin: 8px 0;
    transition: all 0.2s ease;

}
.menu-content:hover {
    background-color: var(--thiBgColor);
    cursor: pointer;
}
.menu-icon {
    font-size: 38px;
}
.desktop-menu {
    font-size: 12px;
    margin-top: 8px;
    font-weight: normal;
}

.navbar-button-random {
    min-width: 80px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 8px;
    border-radius: 14px;
    margin: 8px 0;
    transition: all 0.2s ease;
}
.navbar-button-random:hover {
    background-color: var(--thiBgColor);
    cursor: pointer;
}


.active {
    color: var(--linkColor);
    /*background-color: var(--thiBgColor);*/
}
.active p {
    font-weight: bold;
}

.title {
    flex: 1;
    /*border: 1px solid red;*/

}
.title a {
    text-decoration: none;
    color: white;
}
.title h2 {
    font-size: 24px;
}
.navbar-menu-content {
    display: flex;
    gap: 20px;
    flex-direction: row;
    flex: 2;
    /*border: 1px solid pink;*/
    justify-content: center;
    padding: 10px 0;
}
.navbar-menu-content-container {
    display: flex;
    justify-content: center;
    align-items: center;

    height: auto;
}
.right-content {
    display: flex;
    flex: 1;
    /*border: 1px solid green;*/
}
.navbar-menu-on-phone {
    display: none;
}
.navbar-container a {
    color: white;
}
.title h2 {
    display: inline-flex;
}


@media screen and (max-width: 1120px) {
    .navbar-menu-content {
        display: none;
    }
    .menu-icon-phone-main-btn {
        padding: 30px;
        cursor: pointer;
    }
    .navbar-menu-on-phone {
        display: flex;

        justify-content: center;
        align-items: center;

        gap: 20px;
    }
    .menu-icon-phone-div {
        padding: 30px;
        cursor: pointer;
    }
    .menu-icon-phone {
        font-size: 28px;
        margin-top: -2px;
    }
    .right-content {
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .menu-icon-phone-main-btn {
        display: none;
    }
    .navbar-container {
        padding: 0 10px 0 20px;
    }
    .menu-icon-phone-div {
        padding: 20px;
    }
}